<template>
    <painel titulo="Usuários" icone="pi pi-users" :refreshFunction="atualizar" v-if="mostrarListagem">
        <tabela :data="usuarios" dataKey="usuarioId" :globalFilterFields="['nome', 'login', 'email', 'tipoDescricao']" :stateKey="null">
            <template #checkbox>
                <Checkbox name="Mostrar Usuários Sol" class="ml-3 mb-2 align-content-start lg" v-model="usuarioSol" :binary="true" />
                <label for="binary"> Mostrar Usuários Sol </label>
            </template>
            <template #botoes>
                <btn-inserir></btn-inserir>
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <btn-detalhar :usuario="slotProps.data"></btn-detalhar>
                    </template>
                </Column>
                <Column field="nome" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nome }}
                    </template>
                </Column>
                <Column field="login" header="Login" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.login }}
                    </template>
                </Column>
                <Column field="email" header="Email" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.email }}
                    </template>
                </Column>
                <Column field="statusDescr" header="Status" :sortable="true">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusDescr"></status>
                    </template>
                </Column>
                <Column field="tipoDescricao" header="Tipo" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.tipoDescricao }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import UsuariosServices from './services';
import BtnDetalhar from './BtnDetalhar';
import BtnInserir from './BtnInserir';

export default {
    components: {
        BtnDetalhar,
        BtnInserir,
    },

    data() {
        return {
            usuarios: null,
            usuarioSol: false,
        };
    },

    methods: {
        obterUsuarios() {
            if (this.usuarioSol) {
                this.$store.dispatch('addRequest');
                UsuariosServices.obterTodosUsuariosSol().then((response) => {
                    if (response?.success) {
                        this.usuarios = response.data;
                    } else {
                        this.usuarios = null;
                    }
                    this.$store.dispatch('removeRequest');
                });
            } else {
                this.$store.dispatch('addRequest');
                UsuariosServices.obterTodos().then((response) => {
                    if (response?.success) {
                        this.usuarios = response.data;
                    } else {
                        this.usuarios = null;
                    }
                    this.$store.dispatch('removeRequest');
                });
            }
        },

        atualizar() {
            this.obterUsuarios();
        },
    },

    mounted() {
        this.obterUsuarios();
    },

    computed: {
        mostrarListagem() {
            return this.$route.name === 'Usuarios Sol';
        },
    },

    watch: {
        $route(to) {
            if (to.name === 'Usuarios Sol') {
                if (this.$store.getters.atualizar) {
                    this.obterUsuarios();
                    this.$store.dispatch('setAtualizar', false);
                }
            }
        },
        usuarioSol() {
            this.obterUsuarios();
        },
    },
};
</script>
